@media screen {
  html:not(.printView),
  html:not(.printView) body {
    min-height: 100%;
    max-height: 100%;
    height: 100%;
  }
}

/** Main layout **/

.home-link {
  cursor: pointer;
}

header {
  height: 50px;
  background-color: white;
  z-index: 5;
  position: relative;
}

.header-shadow {
  width: 100%;
  z-index: 3;
  position: relative;
  box-shadow: 0px 6px 5px -5px rgba(0,0,0,0.25);
  height: 13px;
  margin-top: -13px;
}

.header-shadow-hider {
  background-color: white;
  height: 10px;
  margin-bottom: -10px;
  position: relative;
  z-index: 4;
}


header .actions-container {
  z-index: 6;
  width: 100%;
}

#full {
  max-width: 720px;
}

#main-area {
  height: calc(100% - 40px);
}

#right-area {
  height: 100%;
}
body:not(.startView) {
  & #left-area {
    padding-left: 18px;
    padding-right: 15px;
    & .logo {
      margin-left: -18px;
    }
  }

  & #right-area {
    position: relative;
    height: 100%;
    /* Header margin */
    width: calc(100% - 320px);
  }

  & #articleContainer {
    min-height: calc(100% - 50px);
    display: inherit;
    -webkit-overflow-scrolling: touch;    
  }

  & #toc {
    display: block;
  }

  & header {
    height: 95px;
  }
}

#container {
  height: calc(100% - 95px);
}

@media (max-width: 1440px) {
  body:not(.startView) {
    & #full .lexinfo {
      padding-left: 0;
      margin-right: -20px;
    }
    & #left-area {
      width: 260px;
    }
    & #right-area {
      width: calc(100% - 300px);
    }
  }
}

/* Padding between content and right screen edge */
#articleContainer, header {
  padding-right: 40px;
}

/* Two column layout */
@media (max-width: 1250px){
  #articleContainer, header {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }

  .search_bar > div+div {
    padding-left: 1.4em;
  }
  
  footer ul {
    padding: 0px 1.4em;
  }

  body:not(.startView) {
    & #left-area {
      @include display-none();
    }
    & .search_bar {
      width: 100%;
      & > div {
        display: table-cell;
        vertical-align: middle;
      }
    }
    & #right-area {
      width: 100%;
    }
    & #logo-small {
      display: inline !important;
    }
    & .search_bar .moreResults[hidden="false"] {
      display: table-cell !important;
    }
  }
}

@media (max-height: 768px){
  
  .search_bar > div+div {
    padding-left: 1.4em;
  }

  footer ul {
    padding: 0px 1.4em;
  }

  body:not(.startView) {

    & #left-area .moreResults {
      @include display-none();
    }

    & .search_bar {
      width: 100%;
      & > div {
        display: table-cell;
        vertical-align: middle;
      }
    }

    & #toc ul li {   
      margin-bottom: 1px !important;
    }
      
    & .search_bar .moreResults[hidden="false"] {
      display: table-cell !important;
    }
  }
}

@media (max-height: 540px){

   #articleContainer, header {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
  
  body:not(.startView) {

    & #left-area .logo {
      @include display-none();
    }

    & #logo-small {
      display: inline !important;
    }

    & .toc-container {
      font-size: 0.75em;
    }
  }
}

@media (max-height: 250px){
  
  body:not(.startView) {
   & #left-area {
      @include display-none();
    }
  }
}

#left-area, #right-area {
  float: left;
}

#left-area {
  width: 280px;
  margin-left: 40px;
  padding-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#right-area {
  width: 100%;
}


/** OLD **/

#tree {
  height: 100%;
  overflow-x: scroll;
}

#resultView {
  display: flex;
  flex: 1 0 720px;
}

.authorMode {
  & #resultView {
    float: left;
    width: 360px;
    height: 100%;
    padding-left: 0;
    overflow: auto;
    margin-right: 15px;
  }
  & #articleContainer {
    height: 100%;
  }
}

#articleContainer {
  overflow: auto;
}

/* Improves scroll performance in Chrome */
#articleContainer, #container {
  backface-visibility: hidden;
}

#tree,
#articleContainer,
#resultView,
#claim, .spacer{
  display: none;
}

#resultView {
  @media (max-width: 1250px) {
    padding-left: 40px;
  }
  padding-right: 40px;
}

.container {
  margin: 0 auto;
  max-width: 720px;
}

/*body:not(.articleView) {
  & #articleContainer {
    display: none;
  }
}
*/

body.resultListView:not(.authorMode) {
  & #articleContainer {
    display: none;
  }
}

body.resultListView {
  & #toc {
    display: none;
  }
  & #resultView {
    -js-display: flex;
    display: flex;
  }
}

/** Footer **/


footer {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
  font-size: 0.94em;
  display: table;

  overflow: auto;

  user-select: none;

  & ul {
    overflow: auto;
    margin: 0;
    padding: 0 40px 0 40px;
    display: table-cell;
    vertical-align: middle;
    & li {
      display: inline;
      float: left;
      padding-right: 30px;

      &:first-child {
		    padding-left: 20px;
      }
      &:last-child {
        float: right;
        margin-right: 0px;
		    padding-right: 0px;
      }
    }
  }

  &.footer-outer {
    position: fixed;
    bottom: 0;
  }
}

/* Improves scroll performance in Chrome */
#articleContainer, #container {
  backface-visibility: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* begin universal */

#container {
  font-size: 16px;
  line-height: 1.3em;
  width: 100%;

  & h1 {
    font-size: 1.5em;
  }
}

/*#container .herold {
  font-size: 15px;
}*/


/* end universal */
#acc {
  display: none !important;
}
#startContainer {
  display: none;
}

#tooltip,
.full_author {
  position: absolute;
  z-index: 1000;
  display: none;
  background: white;
  border: 1px solid $pschy-color;  
  margin: 5px;
  padding: 6px;
  max-width: 20em;
  /*min-width: 10em;*/
  font-size: 0.8em;
  /*font-size: 10.5px;*/
  line-height: 1.4em;
  /*word-wrap: no-wrap;*/
  color: black;
  /*line-height: 1.5em;*/
}

#tooltip {
  position: relative;
}

.full_author {
  font-size: 1em;  
}

#tooltip u {
  text-decoration: none;
}

#tooltip .readmore {
  display: block;
  margin-top: 0;
  /*font-size: 1.1em;*/
  text-align: right;
  color: rgba(0,0,0,0.8);
}

#tooltip .readmore:before {
  content: '[';
}

#tooltip .readmore:after {
  content: ']';
}


#tooltip .readmore:hover {
  cursor: pointer;
  color: black;
}

#tooltip .tms_link,
#tooltip .tms_concept {
  color: black;
}

/*#tooltip:hover:after {
content: " \1f517";
}*/

#preferences {
  display: none;
  position: absolute;
  z-index: 1000;
  border: 1px solid green;
  padding: 10px;
}


.dialog-container {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  padding: 5%;
}
.dialog {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  
  width: 100%;
  max-width: 720px;
  max-height: 100%;
  overflow: hidden;

  outline: 1px solid $pschy-color;
  background-color: white;

  padding: 25px;
}
.dialog-body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 255px);
}
.dialog .closeButton {
  float: right;
  position: inherit;
}
.dialog textarea {
  resize: vertical;
}
.dialog h1 {
  color: $pschy-color;
  margin-top: 0;
}
.dialog h2 {
  color: $pschy-color;
  font-size: 1em;
  margin: 0;
}

.dialog .row.flex-row {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: 5px;
}
.dialog .row.flex-row > *{
  flex-grow: 1;
  margin-left: 5px;
  margin-top: 5px;
}
.dialog .btn-row {
  text-align: right;
}
.dialog .row {
  margin-top: 10px;
}
.dialog .row:first-child {
  margin-top: 0;
}
.dialog .row > *+* {
  margin-left: 5px;
}

#feedback {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 0.9em;
}
#feedback #fb_response {
  color: $pschy-color;
  margin: 1em 0;
}

#feedback .error, #feedback #fb_response.error {
  color: red;
}

#fb_article_message{
  min-height: 15em;
}
#fb_comment{
  min-height: 5em;
}

#feedback .intro {
  margin-bottom: 10px;
  color: rgba(0,0,0,0.8);
}

#feedback .lemma {
  font-weight: bold;
}

#feedback form {
    flex-grow: 1;
}

#feedback textarea {
  width: 100%;
  display: block;
}

#feedback #article_message {
  height: 100%;
  margin-bottom: 10px;
}

#load {
  display: none;
  color: gray;
  position: absolute;
  bottom: 55px;
  left: 50%;
  opacity: 0.5;  
}

body.loading #load {
  display: block;
}

#impressum_appLinks, .appOnly
{
  display: none;
}

#ccc-icon.ccc-icon--light {
    fill: #000 !important;
}

#cookie-banner {
  display: none;
  position: absolute;
  bottom: 40px;
  border: 1px solid black;
  padding: 1em;
  background: white;
  width: 100%;
  min-width: 100%;

}

#cookie-banner .cb-content {
  float: left;
}

#cookie-banner .cb-buttonGroup {
  float: right;
}

#cookie-banner .cb-buttonBorder {
  border: 2px solid transparent;
  padding: 2px;

  &.selected {
    border: 2px solid color(black tint(45%));
  }
}

#cookie-banner button {
  display: block;
  padding-left:  15px;
  padding-right:  15px;
  width: 350px;
}

#cookie-banner .cookie-container {
  display: none;
}
