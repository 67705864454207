.search_bar {
    overflow-y: initial !important;
    padding-right: 0px;
    max-width: 720px;
    padding-bottom: 5px;

    & .moreResults .btn {
      width: 9em;
    }
    & .moreResults[hidden] {
      display: none;
    }
}

body.resultListView {
  & .moreResults {
    display: none;
  }
}

.empty-div {
    padding: 0 !important;
    width: 0.1px;
}


#search {
    position: relative;
     /*font-size: 0.823em;*/
}

.search_field {
     position: absolute;
     top: 1px; left: 1px; bottom: 0; right: 0;
     outline: none;

     background: 0;
     border: 1px solid rgba(0,0,0,0.33);
     height: 38px;
     width: 710px;
     line-height: 30px;
     padding: 0 0 0 10px;
     margin: 0;  
     width: 708px; 
     border-radius: 0;
 }

.search_field, #autocomplete_results {
    /*color: rgba(0, 0, 0, 0.8);*/
    /*font-weight: bold;        */
}

#autocomplete_results .lowqac
{
    color: gray;
}
 
@media (min-width: 800px) and (min-height: 900px) {
  .startView .search_field {
      height: 50px;
  }
}

.startView .search_field.top {
    background-size: 30px;
}

.search_field.top {
    position: initial;
    fill: $pschy-color;
    background: transparent url(svg/lupe_green.svg) no-repeat right 10px center;
    background-size: 25px;
}

#search .lupe
{
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 5px;
    fill: $pschy-color;
	width: 25px;
	height: 26px;
}

.search_field.top:focus {    
    opacity: 1;
}


 .search_field span {
     /*padding-top: 6px;*/
     /*padding-bottom: 4px;*/
 }

 .search_field.down {
     color: transparent;
     border: 1px solid transparent;
     z-index: -10;
     /*background-color: #EFF5E3;*/
     /*background-color: rgba(239, 245, 228, 0.5);*/
    background-color: white;
 }



#cpcb {
     position: absolute;
     top: 10px;
     right: 10px;
     display: none;
 }

 #cpcb_label {
     position: absolute;
     top: 10px;
     right: 10px;
     display: inline;
     color: #d3d3d3;
 }


 #cpcb:checked + #cpcb_label {
     color: darkgray;
 }

#cpcb_label {
        display: none;
}


 /* begin autocomplete */

.autocomplete-container {
  position: relative;
}

#autocomplete_results {
    list-style: none;
    display: none;
    background: white;
    border: 1px solid rgba(0,0,0,0.33);
    position: absolute;
    transform: translateY(-1px);
    /*max-height: 300px;*/
    /*overflow-y: auto;*/
    width: 720px;
    max-width: 100%;
    z-index: 10000;
    margin: 0;
    padding: 0;
    box-shadow: 0px 10px 10px rgba(128, 128, 128, 0.3);
    table-layout: fixed;
}
#autocomplete_results:empty {
    display: none !important;
}
#autocomplete_results li {    
    cursor: pointer;
    width: calc(100% - 10px);
    /*height: 1.4em;*/
    /*display: table;*/
    /*table-layout: fixed;*/
   padding: 0.25em 0 0.25em 10px;
}

#autocomplete_results .ac {
    color: black;
}

#autocomplete_results .sum {
    color: $pschy-color;
    margin-left: 0.5em; 
    font-size: 0.9em;
   
    /*overflow: hidden;     
    text-overflow: ellipsis;*/
  /*  width: 99%;
    display: inline-block;
     white-space: nowrap;*/
}

#autocomplete_results .hld .sum
{
  color: $herold-color;
}

#autocomplete_results .static .sum
{
  color: gray;
}

#autocomplete_results .ABC-Fach .sum {color: rgba(255, 154, 0, 1);}
#autocomplete_results .Autor .sum {color: rgba(255, 154, 154, 1);}

#autocomplete_results li {
    overflow: hidden;     
    text-overflow: ellipsis;
    /*display: inline-block;*/
    color: $pschy-color;
    white-space: nowrap;
}


#autocomplete_results li:first-child {
    margin-top: 5px;
}

#autocomplete_results li:last-child {
    margin-bottom: 5px;
}


#autocomplete_results li.active {    
    background-color: color($pschy-color tint(85%));
}

#autocomplete_results li.hld.active {    
    background-color: color($herold-color tint(85%));
}

/* end autocomplete */

.actions-container {
    padding-top: 5px;
    height: 35px;
    display: table;
}

#actions
{   
   height: 100%;
   margin: 0;
   padding: 0;
   text-align: right;
   font-weight: 700;
   color: rgba(0,0,0,0.66);
   display: table-cell;
   vertical-align: middle;
}

#actions svg {
    fill: currentColor;
}

#actions li
{
    display: inline;
}

#actions li>span
{
    display: inline-block;
    max-height: 40px;
}
#actions li > *
{
    vertical-align: baseline;
}

#font-size-action span {
    font-family: "Lucida Console", Monaco, monospace;
}
#font-size-action span+span {
    margin-left: 15px;
}

#actions li > span:hover {
    cursor: pointer;
    color: $pschy-color;
}


#font-size-action span.active {
    /*font-weight: bold;*/
}

#font-size-action span:nth-child(1){
    font-size: 75%;
}

#font-size-action span:nth-child(3){
    font-size: 125%;
}

#login-area {
    right: -122px;

    & > * {
        margin: 15px 0;
    }
    & a {
        margin: 10px 0;
        &:nth-of-type(1) {     
            margin-top: 35px;
        }

        font-size: 90%;
        color: gray;
        text-align: left;
        display: block;
        text-decoration: underline;

        &:hover {
            color: black;
            cursor: pointer;
        }
    }
    & input {
        text-indent: 5px;
        width: 100%;
        &[type="submit"] {
            margin: 5px auto;
        }
    }
    & input, & button {
        margin-top: 15px;
        height: 35px;
    }
    & input[type=submit], & button {
        width: 100%;
    }
}

#login-area .subMessage {
  font-size: .9em;
  color: rgba(0,0,0,.66);
  font-weight: normal;
  text-align: left;
}

#login-area .subMessage p a {
  display: inline;
}

#login-area .error {
    font-size: 0.8em;
    color: red;
}

#login-area .logout table {
    text-align: left;
    font-size: 0.9em;
    margin-bottom: 10px;
}

#login-area .logout table td:nth-child(2){
    font-weight: normal;
    padding-left: 8px;
}

#actions > li {
    margin-left: 25px;
}
.actions-container {
    margin-left: -25px;
}

#actions .dropdown {
    position: relative;
    display: inline-block;
}

#actions .dropdown > span{ 
    z-index: 10;
    position: relative;
    padding: 3px 8px 3px 8px;
}

#actions, #actions li, #toc, #tree {
  user-select: none;
}

#actions .dropdown > span {
    border: 2px solid transparent;
    border-bottom: none;
}

#actions .dropdown.active > span {
    border-color: $pschy-color;
    background-color: white;
}

#actions .dropdown.active > div {
    display: initial;
}

#actions input {
    color: black;
    text-decoration: none;
}

.dropdown > div  {
    display: none;
    position: absolute;
    top: calc(100% - 2px);
    width: 340px;
    max-width: 80vw;
    padding: 16px 20px;
    z-index: 1;
    border:2px solid $pschy-color;
    background-color: white;    
}

#login-action > span, #orga-name > span {
    @media (max-width: 400px) {
        max-width: calc(100vw - 230px);
        overflow: hidden;
        white-space: nowrap;
        vertical-align: bottom;
        padding-bottom: 0 !important;
        text-overflow: ellipsis;
    }
    /*margin-right: 45px !important;*/
}

#preferences-action {
    font-size: 0.8em;
    text-align: left;
}

#preferences-action.active span {
    padding-bottom: 14px !important;
}

#preferences-action svg {
    overflow: visible;
}

#preferences-action svg, #login svg {
    margin-bottom: -2px;
}

#preferences-action h2 {
    margin: 0 0 10px 0;
    font-size: 1.1em;
}



#preferences-action > div  {
   right: 0;
}

@keyframes pulse_animation {
    0% { background-size: 25px; }
    50% { background-size: 30px; }
    100% { background-size: 25px; }
}

body.loading .search_field.top {
    animation-name: pulse_animation;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#orga-name {
    color: $pschy-color;
    font-size: 0.75em;
    font-weight: normal;
}


/* #preferences-action input + label {
    position: relative;
    display: none;
}

#preferences-action input + label:before {
    content: '';
    display: inline-block;
    visibility: visible;
    left: 0;
    width: 0.6em;
    height: 0.6em;
    margin: 0 0.3em 0 -1em;
    line-height: 0.6;
    font-size: 1.2em;
    text-align: center;
    
    border: 1px solid #999;
}

#preferences-action input:checked + label:before {
    content: '✔';
}*/