.medcalc {
	& .calcArea {
		padding: 20px 0;
	}
	
	& h2 {
		font-size: 1.1em;
	}

	& .scoreArea {
		padding: 20px 0;
	}
	
	& .inputRow, & .outputRow {
		clear:both;
		border-bottom: 1px solid #CCCCCC;
		padding: 2px 0;
	}

	& .inputCell, & .outputCell {
		display: inline-block;
		padding: 3px 0;
	}

	& input {
		margin-right: 10px;
	}

	& .inputCell:first-of-type {
		min-width: 150px;
	}

	& .inputCaption, & .outputCaption {
		display: inline-block;
		padding: 3px 0;
		width: 20%;
		min-width: 10em;
		vertical-align: top;
	}

	& .inputCaption:after, & .outputCaption:after {
		content: ":";
	}

	& .inputArea {
		margin-left: 1em;
		margin-right: 1em;
	}

	@media (max-width: 600px) {
		& .inputCaption {
			font-weight: bold;
			width: 100%;
			display: inline-block;
		}
		& .inputCaption:after {
			content: "";
		}
	}

	& .outputCell.primary {
		font-weight: 800;
	}
	& .outputCaption.primary {
		font-weight: 800;
	}

	& .text-legal {
		font-size: 85%;
		color: rgba(0, 0, 0, 0.66);
		margin-bottom: 1em;
	}

	& .text-legal > p {
		padding-left: 20px !important;
	}
}

.medcalc .scoreArea {

	& .inputCaption:after, & .outputCaption:after {
		content: "";
	}

	& select{
		width: 100%;
	}	

	& input[type="checkbox"] {
		margin-right: 0px;
		transform:scale(1.5, 1.5);
		position: relative;
		top: 5px;
	}
	
	& .inputCell:first-of-type, & .outputCell:first-of-type {
		min-width: 50px;
	}

	& .inputCell, & .outputCell {
		text-align: center;
		display: table-cell;
		padding-right: 1em;
	}

	& .inputArea, & .inputArea {
		display: table; 
		border-collapse: collapse;		
	}

	& .inputRow, & .outputRow {
		display: table-row;
		border-bottom: 1px solid #CCCCCC;
	}

	& .inputCaption, & .outputCaption {
		display: table-cell;
		padding-right: 1em;
		margin-top: 2px;
		width: initial;
		min-width: initial;
		border-bottom: 1px solid #CCCCCC;
	}

	@media (max-width: 600px) {
		& .inputCell, & .outputCell {
			display: block;
		}
		
		& .inputRow, & .outputRow {
			display: block;
		}
		
		& .inputArea, & .inputArea {
			display: initial;
		}

		& .inputCaption, & .outputCaption {
			border-bottom: 0px;
		}
		
		& input {
			position: initial;
			top: initial;
		}		
	}	
}
