#docs {
      width: 720px;
}

#docs #text_articles_toggle {
  /*background-color: $pschy-color;*/
    font-size: 14px;
    line-height: 20px;
    /*padding: 5px 30px 5px 10px;*/
    margin: 39px 0 15px 0;
    /*color: white;*/
    display: inline-block;    
    font-weight: bold;
    min-width: 200px;
}

/*#docs #text_articles_toggle:hover {
  cursor: pointer;  
  text-decoration: underline;
}
*/
#docs #text_articles_toggle.active span:first-child{
  display: none;
}

#docs #text_articles_toggle.active span:last-child{
  display: inline;
}

#docs #text_articles_toggle span:first-child{
  display: inline;
}

#docs #text_articles_toggle span:last-child{
  display: none;
}


#docs .res em {
    color: $pschy-color !important;
    text-decoration: none;
    font-weight: bold !important;
    font-style: initial;
}

#docs indexentry, #docs .hitNr, #docs .asset,#docs h1 .category, #docs .numerus, #docs .genus {display: none;}


#docs .title h1 {
    margin-bottom: 0;
    display: inline;
    font-size: 1em;
}

#docs .title {
  display: block;
    margin: 25px 0 8px 0;
}
#docs .doc:first-child .title {    
    margin-top: 1em;
}



#docs .terms .edition {
    font-size: 0.823em;
    text-align: right;
    display: inline;
    color: #807F80;
    float: right;
}


#docs a, #docs u {text-decoration: none;}

#docs a:hover {
    text-decoration: none;
    color: initial;
}


#docs .res {
  font-size: 0.823em;
}


#docs .res:hover, #docs .terms:hover {cursor: pointer;}


#docs .terms span.abbr {
     font-weight: normal;
 }


#docs .doc {
  position: relative;
}

#docs .doc {
  margin-top: 50px;
}


#docs .doc:first-child, #docs .doc:nth-of-type(1) {
  margin-top: 0;
}


#docs .edition {
  position: absolute;
    bottom: -20px;
    left: 0;
}


#docs .title
{
  color: $pschy-color;  
}

#docs .res:hover, #docs .terms:hover + .res {background-color: rgba(135,185,20,0.15);}
#docs .doc[data-edition="Herold Innere Medizin"] .res:hover, #docs#docs .doc[data-edition="Herold"] .terms:hover + .res {background-color: rgba(154, 202, 255,0.15);}
#docs .doc[data-edition="ABC-Fach"] .res:hover, #docs .doc[data-edition="ABC-Fach"] .terms:hover + .res {background-color: rgba(255, 154, 0,0.15);}
#docs .doc[data-edition="Autor"] .res:hover, #docs .doc[data-edition="Autor"] .terms:hover + .res {background-color: rgba(255, 154, 154,0.15);}

#docs .doc[data-edition="Herold Innere Medizin"] .title
{
  color: $herold-color;
}

#docs .doc[data-edition="ABC-Fach"] .title
{
  color: rgba(255, 154, 0, 1);
}

#docs .doc[data-edition="Autor"] .title
{
  color: rgba(255, 154, 154, 1);
}

/*#docs .doc[data-edition=Herold] .res
{
  background: rgba(154, 202, 255, 0.95);
}

#docs .doc[data-edition="ABC-Fach"] .res
{
  background: rgba(255, 154, 0, 0.48);
}

#docs .doc[data-edition="Autor"] .res
{
  background: rgba(255, 154, 154, 0.48);
}*/

#docs .res .textref {
  display: none;
}

#docs .hitcount {
  background-color: color($pschy-color tint(85%));
  padding: 50px;
}

#docs .hitcount ul {
  padding-left: 1em;
}

#docs .hitcount svg{
  margin-right: 10px;
}

#docs .hitcount button {
  margin-top: 10px;
  display: block;
}

#docs h3 {
  margin: 0;
  margin-top: 1.5em;
  font-size: 1em;
}

#docs .assetName {
  display: none;
}

.authorMode #docs .assetName {
  display: inline;
  float: right;
  font-size: 0.823em;
  color: #807F80;
}