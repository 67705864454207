.fullWidth {
    width: auto;
    max-width: 100%;
}

.authorOnly {
    display: none;
}

body.authorMode .authorOnly {
    display: initial;    
}

#full article {
    max-width: 1010px;
  /*  display: flex;
    flex-direction: column;*/
    height: 100%;
}

#full sub,
#full sup {
    font-size: 66%;
}

#full h1 .edition,
#full  h1 .category {
    display: none;
}

#full h2 {
    font-size: 1.176em;
    margin-bottom: 0.5em;
    color: color(black tint(33%));
}

#full h2 ~ h2 {
    padding-top: 1em;
}

#full .subhead {
    font-weight: bold;
    color: #2C2B6B;
}

#full .section[data-level='1'] {
    margin-top: 6px;
}

#full .section[data-level='2'] {
    margin-top: 4px;
}

#full p {
    margin: 0;
    padding: 0;
}

#full .section b, #full .section b * {
    color: color(black tint(33%));
}

#full p + p {
    margin-top: 4px;
}

#full .text-footer .edition,
#full .text-footer #full .category {
    display: block;
    font-size: 0.85em;
}

#full .text-footer .name,
#full .genus,
#full .numerus {
    display: none;
}

#full .text-footer .edition:before {
    content: 'Ausgabe: ';
}

#full .text-footer .category:before {
    content: 'Kategorie: ';
}

#full .summary {
    background-color: color($pschy-color tint(85%));
    padding: 15px;
    margin-bottom: 30px;    
    /*float: left;*/
}

#full .herold .summary {
    background-color: color($herold-color tint(85%));
}

#full .herold * ~ .summary
{
    margin-top: 1em;
}

#full .summary>p {
    padding: 0;
    margin: 0;
}

#full .lexinfo {
    float: right;
    color: rgba(0,0,0,0.66);
    padding-left: 20px;
    max-width: 225px;    
    margin-right: -50px;
    width: 0;    
}

#full .lexinfo > ul {
    width: 200px;    
    margin-right: -50px;
    list-style-type: none;
     margin-bottom: 10px;
}

@media (max-width: 1120px) {
    #full .lexinfo > ul, #full .legende > span, #full article .section.authorOnly>div {
        width: 200px;
    }
}

#full h1 {
    font-size: 1.5em;
    line-height: 1.3em;
}

#full section h2 {
    font-size: 1.0em !important;
}

#full>section h2 {
    font-size: 1.7em !important;
}

#full section>section h2 {
    font-size: 1.5em !important;
}

#full section>section>section h2 {
    font-size: 1.3em !important;
}

#full section>section>section>section h2 {
    font-size: 1.1em !important;
}

#full section>section>section>section>section h2 {
    font-size: 1.0em !important;
}

#full  {
    line-height: 1.5em;
    padding-bottom: 3em;
}

/*#full  .herold {
    line-height: 1.4em;
}*/

#full .tms_concept, .extlink a {
    cursor: pointer;
    text-decoration: none;    /*color: rgba(0,57,134,1);*/
}

#full .tms_link {
  cursor: pointer;
}

#full .flowplayer {
  overflow: hidden;
}

#full .tms_concept:hover, #full .tms_link:hover {
    color:gray;
}

.showProperties #full .property {
  background-color: transparent !important;
}
.showLinks #full .tms_concept,
#full .tms_link,
#full .extlink a, #full u {
  border-bottom: 1px dotted black;
}

#full .herold u
{
    border-bottom: 0;
    background-color: transparent;    
}

.showHeroldMarks #full .herold u {
    border-bottom: 0;
    background-color: color($herold-color tint(90%));
 }
 
 #full .herold h2 u, .showHeroldMarks #full .herold h2 u {
    border-bottom: 0;
    background-color: transparent;
 }

 #full .herold .hldBG u, #full .herold .summary u {
    background-color: transparent;
    border-bottom: 2px solid color($herold-color tint(90%));
 }

#full u a {
  border: 0 !important;
  text-decoration: none !important;
}

.showLinks #full .tms_concept:hover,
#full .tms_link:hover,
.extlink a:hover {
  color: gray;
}

#full .summary .tms_concept {

    /*color: rgba(0,57,134,1) !important;*/

    /*text-decoration: underline;*/
    border-bottom: 1px dotted black;
}

#full .breadcrumb br {
    display: none;
}

#full .redfach .breadcrumb {
    display: inline;
}

#full article > .breadcrumb {
    /*position: absolute;*/
    top: 85px;
    font-size: 0.7em;
    color:  $pschy-color;
    max-width: 720px; 
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 12px;
}

#full article > .breadcrumb li:first-child:before{
    content: url(svg/logo-very-small_green.svg);
    size: contain;
    height: 25px;
    width: 25px;
    /*fill: $pschy-color;*/
    position: relative;
    /*top: 2px;*/
    margin-right: 2px; 
}

#full .resultHint {
    color: gray;
}

#full .abbr, #full .genus, #full .numerus {
    font-weight: normal;
}

#full .asset.img img:hover {
    cursor: pointer;
}

#full .lexinfo > ul > li:before {
    display: inline-block;
    content: "> ";
    width: 1em;
    margin-left: -1em;
}

#full .lexinfo b {
    font-weight: bold;
}

#full .lexinfo a {
    color: rgba(0,0,0,0.66) !important;
}

#full .lexinfo .gentext {
    display: none;
}

#full .sections {
    clear: both;
}

#full ul {
    padding-left: 18px;	
}

#full .section ul
{
    margin-top: 0;
    margin-bottom: 0.5em;
}

#full .articleContainer td {
    padding-right: 15px;
    line-height: 1.3em !important;
}

#full .articleContainer td * {
    line-height: 1.3em !important;
}

/*#full ul ul li {
    list-style: square inside url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
    text-indent: -1em;
    padding-left: 1em;
}*/

#full .text-footer {
    clear: both;
    color: #9E9E9E;
    max-width: 720px;
    font-size: 0.85em;
    padding-top: 20px;
    margin-top: 40px;
    border-top: 1px solid black;
}

#full .kasten {
    border: 1px solid color(#9BC84A tint(70%));
    background-color: color(#EBE8DC tint(70%));
    color: color(black tint(70%));
    padding: 3px;
}

/*#full .kasten {margin-bottom: 5px;
    float: right;
  width: 0;
}
*/
#full .reference {
    display: block;
    clear: left;
    font-size: 0.9em !important;
    line-height: 1.2em !important;
    margin-top: 10px;
    font-style: italic;
}

#full .references>ol * {
    font-size: 0.9em !important;
}

#full .legende, #full article .section.authorOnly {
    float: right;
    display: block;
    clear: both;
    font-size: 0.9em;
    line-height: 1.4em !important;
    width: 0;
}

#full .asset .legende .verweistext-stichwort {
    display: none;
}

#full article .section.authorOnly .fbb {
    display: none;
}

#full .legende > span, #full article .section.authorOnly > div {
    width: 200px;
    display: inline-block;
    margin-left: 50px;
}

 #full article .section.authorOnly > div {
    margin-left: 67px;
    width: 250px;
}

.green {
    color: $pschy-color;
    font-weight: bold;
}

@media (max-width: 1440px) {
  #full .legende > span {
    margin-left: 20px;
  }

  #full article .section.authorOnly > div{
    margin-left: 37px;
  }
}

#full .asset.table .legende  {
    /*margin-top: 6px;*/
	margin: 0px 0px 1em 0;
}


#full .legende .leghead {    
    display: block;
    margin-bottom: 0.25em;
    font-weight: bold;
}

#full .legende .tms_link, #full .legende .tms_concept {       
    color: rgba(0,0,0,0.66);
}


#full .asset {
    display: block;
    margin: 20px 0  30px  0;
}

#full .asset img {
    display: block;
    border: 1px solid #E0E0E0;
    padding: 2px;
    margin: 0 auto;
}

#full .asset.img.here img {
    border: 0;
}

#full .asset.table {
    float: initial;
    border: 0;
    display: block;  
}

#full .asset table {
    /*font-size: 0.85em;*/
	font-size: 0.9em;
    /*line-height: 1.3em;*/
	line-height: 1.35em;
    color: color(black tint(10%));

    background-color: color(white shade(8%));
    width: 100%;
}

#full .asset table a:not(.highlighted) {
    color: color(black tint(10%)) !important;
}

#full .asset table td {
    border-bottom: 3px solid white;   
    padding-right: 2px;
    /*padding-top: 1px;*/
	padding-top: 0.3em;
    /*padding-bottom: 3px;*/
	padding-bottom: 0.3em;
    vertical-align: middle;
    position: relative;
}

#full .asset table td .akkolade 
{
    position: absolute;
    left:0; top:0; width:100%; height:100%;
    color: #d3d3d3;
    fill: currentColor;
}

#full li:empty, #full span:empty
{
display: none;
}

#full .asset table td:empty {
    border: 0;
    height: 0 !important;
    padding: 0 !important;
}

#full .asset table th {
    text-align: left;
	background-color: color($pschy-color tint(85%));
    /*background-color: $pschy-color;*/
    /*color: white;*/
   /* border-bottom: 2px solid $pschy-color;
    border-top: 2px solid $pschy-color;*/
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
}

/*#full .asset table th a {
    color: white !important;
}*/



#full .asset table tbody tr:first-child td {
   /* padding-top: 5px;*/
}

#full .asset table tbody tr:last-child td {
    /*padding-bottom: 5px;*/
}


#full .asset table th,
#full .asset table td {
   /*padding-left: 15px !important;*/
}

#full .asset table th:first-child,
#full .asset table td:first-child {
   /*padding-left: 15px !important;*/  
}

.asset.img,
.asset.video {
    width: auto;
}

#full video {
    width: 100%;
    height: 100%;
}


.asset.video .flowplayer {
    max-height: 240px;
}

.asset.img img {
    max-width: 100%;
}

#full .asset table hr {
    /*display: none;*/
   border: 0;
  /*  border-bottom: 2px dashed rgba(128, 128, 128, 0.2);*/
}

#full .property.c_ar {
    background-color: lightcoral;
}

#full .property.c_cas {
    background-color: #F5F5DD;
}

#full .property.c_dichte {
    background-color: violet;
}

#full .property.c_iupac {
    background-color: yellow;
}

#full .property.c_mr {
    background-color: lightcoral;
}

#full .property.c_oz {
    background-color: lightgreen;
}

#full .property.c_schmelzpkt {
    background-color: mediumseagreen;
}

#full .property.c_siedepkt {
    background-color: lightblue;
}

#full .property.c_symbol {
    background-color: lightyellow;
}

#full .property.b_familie {
    background-color: mediumseagreen;
}

#full .property.b_verbreit {
    background-color: burlywood;
}

#full .property.b_pharmteil {
    background-color: orange;
}

#full .links ~ p > .extlink, #full .links ~ .extlink  {
    display: block;
    margin-bottom: 1px;
    padding-left: 20px;
    margin-bottom: 7px;
    font-size: 0.9em;
    line-height: 1.2em;
}

#full .links ~ p >  .extlink:first-child, #full .links ~ .extlink:first-child {
    margin-top: 10px;
}

#full .extlink a: {    
    border: 0;
}

#full .extlink a:before {
    content: url("svg/external.svg");
    margin-right: 4px;
}

/*#full li .extlink a {
    display: inline;
}
*/
#full .textref {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
    font-size: 10px;
    color: grey;
}

#full .resultHint {
    font-size: 0.625em !important;
    font-style: italic !important;
    font-weight: normal;
}

/*#full [data-cid='PSCHY_kw_artikel_1cfa8fa2-7f09-4ef3-b88f-dbce7b5061f9'] {pointer-events: none; cursor: default; color: #000;}*/

/* HEROLD */

#full article.herold > .breadcrumb li:first-child:before{
    content: url(svg/logo-very-small_herold.svg);
}
#full article.herold > .breadcrumb>li+li:before {
	color: $herold-color;
}

#full article.herold .breadcrumb {
	color: $herold-color;
}

#full .herold b {
    font-weight: bold;
}

#full .herold h2, #full .herold h2 * {
    color: color($herold-color tint(50%));
}

#full .herold h2 *, #full .herold .h2 *, #full .herold .h2 + u, #full .herold .h2 + b + u {
    border-bottom: 0;
}

#full .herold .h2 {
    font-weight: bold;
    font-size: 1.176em;
    color: color($herold-color tint(50%));
}

#full .herold .h2 * {
    font-weight: bold;
    color: color($herold-color tint(50%));
}


#full article.herold {
    & h2 u {
        text-decoration: none;
        border: 0;
    }

    & h2 .tms_concept {
        color: #2C2B6B;
    }

    & p>b:first-child {
        display: inline-block;
        margin-top: 6px;
    }
    & ul>b:first-child,
    & div>b:first-child {
        display: inline-block;
        white-space: pre;
        margin-top: 6px;
    }
}

#full .herold .hldBG {
    background-color: rgba(211, 211, 211, 0.5);
}

#full .herold .hldBG li, #full .herold .hldBG ul {
    background-color: rgba(211, 211, 211, 0.5);
}

#full .herold .asset table th, #full .herold p.hldBG {
    background-color: rgba(211, 211, 211, 0.5);
	padding: 0em 0 0.5em 0em;
}

#full .herold p.hldBG {    
	padding: 0 0.5em 0.5em 0.5em;
    margin: 0; 
}

#full .herold *:not(.hldBG) + p.hldBG
{
    margin: 1.2em 0 0 0;
}

#full .herold p *,
#full .herold li * {
    text-indent: initial;
}

#full .herold .articleTOC {
    margin-top: 60px;
}

#full .herold .articleTOC {
    margin-top: 60px;
}

#full .innerTOC {
    font-weight: bold;
}

#full .innerTOC * {
    font-size: 1.25em !important;
    border-bottom: 0 !important;
}

#full .innerTOC.pre {

    /*margin-left: 20px;*/
    font-weight: normal;
    font-size: 0.85em !important;
}

/*#full .herold table {
    table-layout: fixed;
}*/

/* inner toc herold */

#full > .herold  > .innerTOC > li {
	margin: 0 0 0.5em 0;
	& > ul {
		margin: 0 0 2em 0;
	}
	
}

#full .herold h3 {
    font-size: 1.8em;
	line-height: 1.2em;
}

#full .title_icd {
    display: none;
}

#full .herold p + p {
    margin-top: 8px;
}

#full .herold .fbb, #full .herold .printButton {
    display: none !important;
}

#full .breadcrumb {
    list-style: none;
    overflow: hidden;
    background: 0;
}

#full .breadcrumb li {
    /*float: left;*/
    display: inline;
}

#full .breadcrumb>li+li:before {
    padding: 0px;
    /*color: rgba(0,0,0,0.5);*/
    color: $pschy-color;
    content: " > ";
}

#full .breadcrumb a:hover,
#full .innerTOC a:hover {
    cursor: pointer !important;
}

#full .breadcrumb a {    
    text-decoration: none !important;
    border-bottom: 0 !important;
}

#full .herold  ul {
/*    padding-left: 10px;*/
	margin-top: 5px;
}

#full .herold .innerTOC ul ul * {
    font-size: 1em !important;
}

#full .herold .innerTOC ul ul ul * {
    font-size: 0.85em !important;
}

#full .full_author b {
    font-weight: bold;
}

#full .full_author .closeButton {
    font-size: 0.75em;
}


#full .author {
    border-bottom: 1px dotted gray;    
}

#full .author:hover {
    cursor: pointer;
    color:black;
}

#full .lexinfo, #full .legende, #full .text-footer, #full article .section.authorOnly {
    color: color(black tint(34%));
    font-size: 0.823em;
    line-height: 1.6em;
}


#full .section ul>li>ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 1em;
    
}
#full .section ul>li>ul>li:before {
    display: inline-block;
    content: "–";
    width: 1em;
    margin-left: -1em;
    font-weight: 400;
}

#full .section ul>li>ul>li>ul>li:before {
    display: inline-block;
    content: "▫";
    width: 1em;
    margin-left: -1em;
    font-weight: 400;
}

#full .herold .section ul li {
    list-style-type: none !important;
}

#full .herold .section ul li:before {
    display: inline-block;
    content: "•" !important;
    width: 1em;
    margin-left: -1em;
    font-weight: 400;
}

#full .text-footer b {
    font-weight: bold;
}
body:not(.authorMode) #full .text-footer .footer-row.articleID {
    display: none;
}

#full .breadcrumb {
    padding-left: 0;
}

#full .highlighted2 {  
  color: $pschy-color !important;
}

#full .asref, #full .aslegend {
    display: block;
}

#full .herold .hldnext {
    margin-top: 1em;
}

#full .herold .hldnext .btn:hover {
    color: white;
}

#full .herold .hldnext a, #full .herold .hldnext a *{    
    color: $herold-color;
    font-weight: bold;
    border-bottom: none;
}

#full .herold .hldnext a{    
    padding-left: 0.5em;
}

#full .herold .hldnext > * {
    display: table-cell;    
}

#full .subpage .breadcrumb {
    display: none;
}

#full .green {
    color: $pschy-color;
}

#tooltip .hint {
    font-size: 0.9em;
    color: gray;
    display: inline-block;
}

.hintDone #tooltip .hint {
    display: none;
}

.highlightedoff {
    color: inherit !important;
    border: 0 !important;
    cursor: default !important;
}

.highlightedoff:hover {
    color: inherit !important;
    cursor: default !important;
}
