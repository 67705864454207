.grid-custom, .grid-2, .grid-3, .grid-4, .grid-6 {
	&:after, &:before {
		display: table;
		content: " ";
	}
	&:after {
		clear: both;
	}
	
	& .grid-elm {
		float: none;
	}

	/* Trick for stretching columns to 100% height */
	& .grid-elm {
    	padding-bottom: 100%;
    	margin-bottom: -100%;
    }
    overflow: hidden;
}

.grid-2 {
	& .grid-elm {
		@media (min-width: 500px) {
			float: left;
			width: 50%;
		}
	}
}

.grid-3 {
	& .grid-elm {
		@media (min-width: 900px) {
			float: left;
			width: 33.333333%;
		}
	}
}

.grid-4 {
	& .grid-elm {
		@media (min-width: 500px) {
			float: left;
			width: 50%;

			&:nth-child(2n+1) {
				clear: left;
			}
		}

		@media (min-width: 1200px) {
			float: left;
			width: 25%;

			&:nth-child(2n+1) {
				clear: none;
			}
		}
	}
}

@mixin grid-6-custom($point1, $point2, $point3) {
	& .grid-elm {
		@media (min-width: $point1) {
			float: left;
			width: 50%;

			&:nth-child(2n+1) {
				clear: left;
			}
		}

		@media (min-width: $point2) {
			width: 33.333333%;


			&:nth-child(2n+1) {
				clear: none;
			}
			&:nth-child(3n+1) {
				clear: left;
			}
		}

		@media (min-width: $point3) {
			width: 20%;

			&:nth-child(3n+1) {
				clear: none;
			}
		}
		
	}
}

.grid-6 {
	@include grid-6-custom(500px, 900px, 1366px);
}
