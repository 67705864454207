body:not(.printView) {
	& .license-info {
		display: none;
	}
}

.license-info {
	color: $pschy-color;
	font-size: 0.8em;
	height: 50px;

	& > * {
		display: table-cell;
		vertical-align: middle;
	}

	& svg {
		height: 24px
	}
}

@page {
	size: auto;
	margin: 1.75cm;
}

table, .section {
	break-inside: auto;
}
tr, img, .asset {
	break-inside: avoid;
	break-before: auto;
	break-after: auto;
}

body.printView {
	& #articleContainer {
		padding-left:0;
		padding-right:0;
	}
	& header,
	& #left-area,
	& footer,
	& .breadcrumb,
	& .fbb
	{
		display: none !important;
	}
	& #container {
		margin-top: 0;
		margin-bottom: 0;
	}
	& #right-area {
		width: 100% !important;
	}
	& #main-area {
		height: auto !important;
	}
}