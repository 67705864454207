@font-face {
	font-family: "Gotham-Book";
	src: url("../fonts/Gotham-Book.otf");
}

.more-infos {
	font-family: "Gotham A", "Gotham B", "Gotham-Book";
	line-height: 1.4em;
}

body:not(.startView) {
	& .startview-only {
		display: none !important;
	}
}

#book-launch {
	/*max-height: 400px;*/
	font-size: 1.3em;
	line-height: 1.3em;
	text-align: center;
	display: none;

	position: absolute;
	left: -150px;
	top: 50%;
	transform: translateY(-50%);

	@media (min-width: 1366px) {
		display: block;
	}
}

#book-launch img:hover {
	cursor: pointer
}
#book-launch div {
	/*margin-top: 1em;*/
}
#book-launch button {
	margin-top: 1em;
	font-weight: normal;
	background-color: white; 
	font-size: 0.8em;
    color: rgba(0,0,0,0.66);
    border: 1px solid #959595;
    padding-left: 20px;
    padding-right: 20px;    
	cursor: pointer;
}

#book-launch button:hover {
	background-color: $pschy-color;
    color: white; 
    border: 1px solid $pschy-color;
    text-decoration: none;
}



#auch-als-app {
	max-height: 400px;
	text-align: center;
	display: none;

	position: absolute;
	left: -100px;
	top: 50%;
	transform: translateY(-50%);

	@media (min-width: 1366px) {
		display: block;		
	}
}

#meduplus-stoerer {
	/*max-height: 400px;*/
	font-size: 1.3em;
	line-height: 1.3em;
	text-align: center;
	display: none;

	position: absolute;
	right: -150px;
	top: 50%;
	transform: translateY(-70%);

	& > img {
		cursor: pointer;
		width: 100%;
    }
}

#meduplus-stoerer.meduplus-stoerer-aktiv {
	
	@media (min-width: 700px) {
			display: block;
			width: 150px;
    		right: 10px;
    		top: 200px;
	}
}

#meduplus-stoerer.meduplus-stoerer-aktiv {
	
	@media (min-width: 1100px) {
			display: block;
			width: 150px;
    		right: -30px;
    		top: 50%;
	}
}

#meduplus-stoerer.meduplus-stoerer-aktiv {
	
	@media (min-width: 1200px) {
			display: block;
			width: 200px;
    		right: -70px;
    		top: 50%;
	}
}


#meduplus-stoerer.meduplus-stoerer-aktiv {
	
	@media (min-width: 1300px) {
			display: block;
			width: 250px;
    		right: -115px;
    		top: 50%;
	}
}

#meduplus-stoerer.meduplus-stoerer-aktiv {
	@media (min-width: 1366px) {
			display: block;
			width: 300px;
			right: -150px;
			top: 50%;
	}
}

.more-infos-button {
	width: 40px;
	height: 40px;
	padding: 0;
	border-radius: 50%;
	background-color: $pschy-light;
	margin-top: 0.5em;
	fill: $pschy-color;
	cursor: pointer;
}

.arrow-button {
	position: relative;
	text-align: center;

	& > .inner {
		height: 100%;
	}

	& svg {
		margin: 37% 0;
		width: 50%;
		height: 33.33333%;
	}
}

.collapse-container.enabled .collapse-visible .arrow-button .inner {
	transform: rotateX(180deg);
}

.more-infos {
	text-align: center;

	& .header {
		color: $pschy-color;
		font-size: 1.5em;
		position: relative;

		box-shadow: 0px 6px 5px -5px rgba(0,0,0,0.25);
	}
}
.bar {
	border-bottom: 60px solid $pschy-color;
	margin-top: -20px;
}

.pschy-bg-light {
	background-color: $pschy-light;
	color: $text-color;
}

.page {
	position: relative;
	padding: 5vh 5%;
	@media (min-width: 460px) {
		padding: 5vh 10%;
	}

	& h3 {
		text-transform: uppercase;

		& small {
			display: block;
			font-weight: normal;
		}
	}

	& .grid-elm {
		& .body {
			padding: 40px 30px;

			& button, & .btn, & input:not([type='checkbox']) {
				padding: 5px;
				width: 190px;
				height: 35px;
				font-weight: normal;
				margin-top: 10px;
			}
		}
	}


	& > img {
		max-width: 100%;
		height: auto;
	}

	& .collapse-container.enabled .collapse-button {
		cursor: pointer;
	}
	& .collapse-container:not(.enabled) .collapse-target {
		display: block !important;
	}
}

.page .body.collapse-container {
	padding: 0;
	& .collapse-target {
		padding: 0 30px 30px 30px;
	}
}

.page.features {
	& svg {
		width: 80px;
		height: 80px;
	}

	padding: 0;
	color: white;
	fill: white;

	& .grid-elm {
		background-color: $pschy-color;
	}

	& .grid-elm.herold {
		background-color: $herold-color;
	}
	& .grid-elm.mobil {
		background-color: $pschy-light;
		color: $text-color;
		fill: $text-color;
	}
}

.circle {
	border-radius: 50%;
	background-color: black;
}

.collapse-button .circle {
	background-color: $pschy-color;
	width: 32px;
	height: 32px;
	fill: white;

	display: block;
	margin: 15px auto 0 auto;
}

.collapse-container.enabled {
	& .collapse-button {
		position: relative;
		padding-left: calc(1.2em + 7px);
		text-align: left;
		& .circle {
			margin: 0;
			height: 1.2em;
			width: 1.2em;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
		}
	}
	& .collapse-target {
		display: none;
	}
}


#page-testen-student {
	text-align: left;
}

#kaufen-redeem {
	text-align: left;
}

#trial_response,#trial_student_response,#redeem_response {
	border: 2px solid #87b914;
	display:  none;
	margin: 1.5em 0;
	padding: .55em;

	&.error {
		border-color: red;
	}
}

.testen-back-button,.redeem-back-button {
	fill: white;
	transform: rotate(90deg);
	cursor: pointer;

	width: 1em;
	height: 1em;
	background-color: $pschy-color;

	margin-right: 10px;

	display: inline-block;

	& > svg {
		height: 8px;
		width: 100%;
	}
}

.form-table {
	display: table;
	border-spacing: 10px;

	& .form-row {
		display: table-row;
	}
	& .form-cell {
		display: table-cell;

		&:last-child {
			width: 100%;
		}
	}

	& input {
		width: 100%;
	}

	& input[type='checkbox']{
		width: initial;
	}
	
	& input[type='submit']{
		width: initial;
	}
}

.page.app {
	position: relative;
	overflow: hidden;

	& > .body {
		& h2 {
			font-weight: normal;
			text-transform: uppercase;
		}
		& > p {
			font-size: 1.2em;
		}

		& .grid-elm .body {
			padding: 10px;
			font-size: 0.9em;
		}

		& .circle {
			fill: #C8CACA;
			background-color: black;
			width: 55px;
			height: 55px;
			padding: 12px;
			display: inline-block;

			& svg {
				width: 100%;
				height: 100%;
			}
		}

		& .button {
			width: 160px;
			height: auto;
			padding: 5px;
			background-color: black;
			color: #C8CACA;
			fill: #C8CACA;
			text-transform: uppercase;
			font-size: 13px;

			& svg {
				width: 20px;
				height: 20px;
			}

			& > * {
				vertical-align: middle;
			}
		}
		& .button + .button {
			margin-left: 20px;
		}
	}


	background-color: #C8CACA;

	& > div.img {
		position: relative;
		background: linear-gradient(to bottom, #D4D4D4 0%,#b0b0b0 100%);
		margin-bottom: 40px;
		overflow: hidden;

		margin-top: -5vh;
		margin-left: -10vw;
		margin-right: -10vw;

		& img {
			width: 100%;
			margin-bottom: -10px;
		}
		& svg {
			position: absolute;
			fill: $herold-color;
			max-width: 30vw;
			max-height: 40vw;
			left: 10%;
			top: 10%;
		}
	}
	
	@media (min-width: 800px) {
		& > .body {
			width: 70%;
			position: relative;
		}
		& > div.img {
			position: absolute;
			z-index: 0;
			height: 60vw;
			right: -20vw;
			bottom: -10vw;

			background: none;
			margin: 0;

			overflow: visible;

			& svg {
				left: auto;
				top: -20%;
				right: 30vw;
			}
		}
		& img {
			width: auto;
			margin-bottom: 0;
		}
	}

	@media (min-width: 1200px) {
		& > .body {
			width: 57%;
		}
		& > div.img {
			right: -10vw;
			height: 50vw;

			& svg {
				top: -10%;
			}
		}
	}

	@media (min-width: 1366px) {
		& > div.img {
			height: calc(100% + 50px);
			bottom: -10vw;

			& svg {
				top: -5%;
				right: 39%;
			}
		}
	}
	@media (min-width: 1600px) {
		& > div.img {
			bottom : 0;
			right: 0;
			height: 100%;
			/*top: -50px;*/

			& svg {
				top: 5%;
			}
		}
	}

}

body.startView {
	user-select: none;

	/* Header without search bar */
	& #container {
	  height: calc(100% - 50px);
	}

	& .startview-container {
		min-height: calc(100% - 100px);
		max-width: calc(800px + 20%);
		margin: 0 auto;
	}

	& header {
		padding-left: 40px;
	}

	& .logo-center {
		display: block;
		max-width: 80vw;
		margin: 0 auto;
		
		& > svg {
			display: block; 
			max-width: 100%;
			max-height: 225px;
			height: auto;
			margin: 0 auto;
			cursor: default;
		}
	}

	& #logo-stoerer {
		position: relative;
		height: 0px;
        & > svg {
			cursor: pointer;
			fill: #E41F21;
			height: 120px;
			width: 120px;
			display: block;
			margin: 0 0 0 auto;
        }
    }

	& #claim {
		display: block;
		color: rgba(0,0,0,0.66);
		font-weight: normal;
		margin: 0 auto;
		padding: 0;
		width: 306px;
		margin-bottom: 60px;
	}

	& .search_bar {
		margin: 0 auto;
		max-width: 750px;
	}

	& #search {
		margin: 0;
	}

	& #left-area, & #articleContainer {
		@include display-none();
	}


	& #moreInfo {
		clear: both;
		display: block;
		margin: 0 auto;
		padding: 0.5em 1em;
	}

	& #startview-home {
		float:left;
		display: block;
		&:hover {
	  		opacity: 0.5;
		}
		& > svg {
			margin-top: 11px;
			vertical-align: bottom;
		}
	}
}

#startView_actions {
	@include grid-6-custom(250px, 350px, 900px);

	font-size: 0.9em;
	@media(max-width: 410px) {
		font-size: 0.8em;
	}

	& .body {
		margin-bottom: 10px;
		padding: 0;

		& svg {
			display: block;
			margin: 0 auto 20px auto;
			width: 72px;
			max-width: 100%;
			fill: $pschy-color;
		}
	}

	color: rgba(0,0,0,0.66);

	width: 100%;	
	padding: 0;
	white-space: nowrap;

	text-align: center;


	& #hld-action svg {				
		fill: $herold-color;
	}

	& .isInactive
	{
		position: relative;
		display: block;
		top: -64px;
		font-size: 0.75em;
	}
}
 
body.HLD .heroldOnly {
	display: inline-block !important;
}

.startview-action {
	cursor: pointer;
	&:hover {
		opacity: 0.75;
	}
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%; 
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-right: 0%;
    width: 100%; 
  }

  to {
    margin-right: 100%;
    width: 300%;
  }
}

body.startView {
	& #left-area {
		display: none;
	}
	& .spacer {
		display: block;
	}
	& .container {
		max-width: 750px;
	}

	& #newestArticleScroller {
		clear: both;
		height: 4em;
		position: relative;
		overflow: hidden;
		font-weight: 80%;

		& ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			position: absolute;			
			width: 9000px;

			& li {
				margin: 0 0 0;
				font-weight: bold;
				max-width: 750px;
				/*width: 100%;
				
				text-align: center;
				position: absolute;*/
				display: inline;
				cursor: pointer;
				color: rgba(0,0,0,0.66);

				&::after {
					content: "+++";
					color: $pschy-color;
					font-weight: normal;
				    size: contain;
				    height: 25px;
				    width: 25px;
				    position: relative;
				    margin: 0 8px;
				}

				& span {
					border-bottom: 1px dotted;
				}

				&.in {
				  animation-duration: 3s;
				  animation-name: slidein;
				}

				&.out {
				  animation-duration: 3s;
				  animation-name: slideout;
				}

				&:hover{
					color: color($pschy-color tint(25%));
				}

				&:after:hover{
					color: rgba(0,0,0,0.66);
				}
			}
		}

	}
}

.spacer {
	display: block;
	width: 100%;
	height: 80px;
}

@media(max-height: 700) {
	.startView .spacer {
		height: 10px;
	}
}
@media(max-height: 970px) {
	.startView .spacer {
		height: 25px;
	}
}

@media(max-height: 1100px) {
	.startView .spacer {
		height: 35px;
	}
}

@media(max-height: 785px) {
	.startView .spacer {
		height: 15px;
	}
}

/* Make spacers smaller earlier because our site got higher */
@media (max-width: 639px) and (max-height: 1100px) {
	.startView .spacer {
		height: 45px;
	}
}
@media (max-width: 639px) and (max-height: 900px) {
	.startView .spacer {
		height: 10px;
	}

	.startview-container {
		padding-top: 0;
	}
}

@media(max-width: 830px) {
	footer ul li:last-child
	{
		float: left;
		margin-left: 60px;	
	}	
}

