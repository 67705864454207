$pschy-color: #87B914;
$pschy-light: #EBF6CF;
$herold-color: #0A376E;
$text-color: #333;

/*$pschy-color: #7DB940;*/

/* For some reason IE ignores "display: none;" sometimes. This is a fix
 * to hide it anyway. 
 */
@mixin display-none() {
  display: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@mixin vcenter() {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

*,
*:before,
*:after {
  box-sizing: border-box;

  -webkit-print-color-adjust: exact;
}

a {
  color: inherit;
}

.vcenter {
  @include vcenter();
}

.uppercase {
  text-transform: uppercase;
}

.demi {
  /*font-family: "NotoDemiLight";*/
  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-size: 1.1em;
}

html,
body {
  margin: 0;
  padding: 0;
  /*font-family: Verdana !important;*/
  /*font-family: 'Segoe UI', Selawik, Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;*/
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
   /* why? */
  /*width: 99.98% !important;*/
  overflow: hidden;
}

svg.logo, .logo svg {
  cursor: pointer;
  fill: $pschy-color;
  
}

.hasHerold #logo svg.pschy {
  display: none;
}
/* http://stackoverflow.com/questions/27866893/svg-fill-not-being-applied-in-firefox */
.hasHerold #logo svg.herold, .hasHerold #logo svg.herold use>svg {
  display: block;
  fill: $herold-color;
}

#logo svg.pschy {
  display: block;
}

#logo svg.herold {
  display: none;
}

.hasHerold .logo-small, .hasHerold .logo-small use svg {
 fill: $herold-color !important; 
}

/*.hasHerold #logo-small svg.pschy, .hasHerold .logo-small svg.pschy {
  display: none;
}

.hasHerold #logo-small svg.herold, .hasHerold .logo-small svg.herold {
  display: inline;
  fill: $herold-color;
}

#logo-small svg.pschy, .logo-small svg.pschy {
  display: inline;
}

#logo-small svg.herold, .logo-small svg.herold {
  display: none;
}*/


/* article overrides.. */
u {
  text-decoration: none !important;
  /*border-bottom: 1px dotted black;*/
}

.hidden,
.forcehidden {
  display: none;
}

.hiddenImportant {
  display: none !important;
}

.bold,
.bold ~ * {
  font-weight: bold;
}


.concept.highlight {
  background-color: yellow;
}
.concept {
  outline: 0 !important;
}

.highlighted, .highlight, mark {
  font-weight: bold;
  color: $pschy-color !important;
  background-color: transparent;
}

.herold .highlighted, .herold .highlight, .herold mark {
  font-weight: initial;
  color: initial !important;
  background-color: initial;
}

.closeButton {
  position: absolute;
  right: 2px;
  top: -2px;
  cursor: pointer;
  font-size: 0.8em;
  color: $pschy-color;
}

.btn {
  background-color: $pschy-color;
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  border: 0;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: color($pschy-color tint(25%));
  }
  & svg {
    fill: currentColor;
  }
}

.moreResults .btn {
  width: 8.5em;
  background-color: gray;
  &:hover {
    background-color: color(gray tint(25%));
  }
}

.moreResults.pschyButton .btn {
  background-color: $pschy-color;
  &:hover {
    background-color: color($pschy-color tint(25%));
  }
}

.heroldButton .btn, .herold .btn {
  background-color: $herold-color;
  &:hover {
    background-color: color($herold-color tint(25%));
  }
}


button,
input[type=submit] {
  background-color: $pschy-color;
  border: 2px solid $pschy-color;
  color: white;
  border: 0;
  padding-left: 35px;
  padding-right: 35px;
  font-weight: bold;

  &:hover {
    /*font-weight: bold;*/
    text-decoration: underline;
  }
}

button.secondary {
  background-color: #fff;
  border: 2px solid $pschy-color;
  color: $pschy-color;
}

.closeable {
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform:translateZ(1px);
}

body.startView #backDiv {
  display: none;
}

#backDiv {
    margin: 20px 0;
   color: color(black tint(25%));
}

#backDiv svg {
  fill: color(black tint(25%));
}

#cbAlert
{
  position: absolute;
  bottom: 40px;
  left: 0px;
  width: 100%;
  background-color: white;
  border: 2px solid $pschy-color;
  margin: 0;
  padding: 1% 3%;
  z-index: 10000;
  font-size: 80%;
}

#cbAlert button {
  padding : 3px;
}


