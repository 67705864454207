@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Noto-Sans.eot);
  src: local('Noto Sans'), local('NotoSans'), url(../fonts/Noto-Sans.woff) format('woff'), url(../fonts/Noto-Sans.eot?#iefix) format('embedded-opentype'), url(../fonts/Noto-Sans.woff2) format('woff2'), url(../fonts/Noto-Sans.ttf) format('truetype'), url(../fonts/Noto-Sans.svg#NotoSans) format('svg');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(../fonts/Noto-Sans-Bold.woff) format('woff'), url(../fonts/Noto-Sans-Bold.woff2) format('woff2'), url(../fonts/Noto-Sans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(../fonts/Noto-Sans-Italic.woff) format('woff'), url(../fonts/Noto-Sans-Italic.woff2) format('woff2'), url(../fonts/Noto-Sans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'), url(../fonts/Noto-Sans-Bold-Italic.woff) format('woff'), url(../fonts/Noto-Sans-Bold-Italic.woff2) format('woff2'), url(../fonts/Noto-Sans-Bold-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(../fonts/Source-Sans-Pro-Light.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Light.woff) format('woff'), url(../fonts/Source-Sans-Pro-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Source-Sans-Pro.eot);
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url(../fonts/Source-Sans-Pro.ttf) format('truetype'), url(../fonts/Source-Sans-Pro.woff) format('woff'), url(../fonts/Source-Sans-Pro.svg#SourceSansPro) format('svg'), url(../fonts/Source-Sans-Pro.woff2) format('woff2'), url(../fonts/Source-Sans-Pro.eot?#iefix) format('embedded-opentype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(../fonts/Source-Sans-Pro-Bold.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Bold.woff) format('woff'), url(../fonts/Source-Sans-Pro-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url(../fonts/Source-Sans-Pro-Black.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Black.woff) format('woff'), url(../fonts/Source-Sans-Pro-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'), url(../fonts/Source-Sans-Pro-Light-Italic.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Light-Italic.woff) format('woff'), url(../fonts/Source-Sans-Pro-Light-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-It'), url(../fonts/Source-Sans-Pro-Italic.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Italic.woff) format('woff'), url(../fonts/Source-Sans-Pro-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'), url(../fonts/Source-Sans-Pro-Bold-Italic.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Bold-Italic.woff) format('woff'), url(../fonts/Source-Sans-Pro-Bold-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackIt'), url(../fonts/Source-Sans-Pro-Black-Italic.ttf) format('truetype'), url(../fonts/Source-Sans-Pro-Black-Italic.woff) format('woff'), url(../fonts/Source-Sans-Pro-Black-Italic.woff2) format('woff2');
}

