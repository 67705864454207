
#full .subpage.footer p, #full .subpage.footer ul, #full .subpage.footer td {
	font-size: 0.8em;
	line-height: 1.5em;
}

#full .subpage.footer p {
	margin: 1em 0 !important;
}

#full .subpage.footer b{
	font-size: 1em;
}

#full .subpage.footer h2
{
	color: white;
	background-color: $pschy-color;
	padding: 4px;
}

#full .subpage.footer h3
{
	font-size: 1em;
	font-weight: normal;
	color: $pschy-color;
	line-height: 1.4em;
}

#full .subpage.footer emph
{
	font-size: 0.8em;
	text-decoration: underline;
}

#full .subpage.footer.banner img
{
	display: block;
	margin-top: 1.5em;
}

#full .subpage.footer.banner pre
{
	padding: 0;
	margin: 0;
	overflow: visible;
	font-size: 0.8em;
	line-height: 1.2em;
	margin-top: 0.5em;
	border-bottom: 1px solid black;
}
