.subpage.overview .head
{
    height: 168px;
    color: white;
    background-color: $pschy-color;
    text-align: center;   
    font-size: 1.5em;
    width: 100%;
	margin-top: 0.67em;
}

.subpage.overview .head svg
{    
    margin-top: 30px;
    fill: $pschy-color;
}

#full ul.subpage-overview
{
    margin: 7.5px -7.5px 0 0;
    padding: 0;
    list-style-type: none;
    font-size: .8em;

    & li {
    	display: inline-block;
    	float: left;
        width: 235px;
        height: 54px;
        background-color: #f0f0f0;
        margin: 0 7.5px 7.5px 0;
       	padding: 10px;
       	line-height: 1.3em;
    }
}

#full .subpage b {
	font-weight: bold;
	font-size: 1.2em;
}

#full .subpage.overview b {
	display: block;
}

li[data-subpage]:hover {
  cursor: pointer;
  opacity: 0.75;
}

#full .subpage.overview li[data-subpage]:hover {
  cursor: pointer;
  opacity: 1;
  background-color: $pschy-color; 
  color: white;
}

.back-button {
    height: 1em;
	width: 1em;
	margin-right: 0.5em;
	cursor: pointer;
    float: left;
    & > svg{
        height: 100%;
        fill: $pschy-color;
    }
    &:hover > svg {
        fill: color($pschy-color tint(30%));
    }
}

#full .subpage.classification ul {
    padding-left: 10px;
	text-indent: -18px;
    margin-bottom: 0.2em;
}

#full .subpage.classification li {
    /*border-top: 1px solid $pschy-color;*/
    /*border-left: 1px dotted $pschy-color;*/
    border-left: 1px dashed rgba(0,0,0,0.1);
    font-size: 0.95em;
    color: color(black tint(10%));
}

#full .subpage.classification .leaves li {
    color: $pschy-color;
}

#full .subpage.classification label
{
	/*border-top: 1px solid $pschy-color;
    border-left: 1px dotted $pschy-color;*/
	display: block;
    cursor: pointer;
}


#full .subpage.classification li[haschildren="true"] > label:hover {    
    cursor: pointer;
    opacity: 0.5; 
}

#full .subpage.classification ul, #full .subpage.classification ul ul li
{
	list-style: none !important;
	padding-left: 1em;
}

#full .subpage.classification input[type = "checkbox"] {
    display: none;
}

#full .subpage.classification input[type = "checkbox"]:checked + label, #full .herold.subpage.classification > ul > li > label {
    font-weight: bold;
}

#full .subpage.classification input[type = "checkbox"]:not(:checked) ~ ul {
    display: none;
}

#full .subpage.classification label[for]::before {
    display: inline-block;
    width: 12px;
    height: 8px;
    margin-left: 3px;  
    margin-right: 3px;  
    /*float: right;*/
    /*opacity: 0.5;*/
    transform: rotate(270deg);
    content: "";
}
#full .subpage.classification input ~ label[for]::before {
    background-image: url('svg/arrow.svg');
    background-size: 12px 8px;
    transform: rotate(270deg);
}

#full .subpage.classification  input[type = "checkbox"]:checked ~ label[for]::before {
   transform: rotate(0deg);
}

#full .subpage.classification > .search {
	/*text-align: right;*/
    margin-bottom: 5px;   
    text-indent: 10px;
}

#full .subpage.classification > .search > input {	
    /*border: 1px solid $pschy-color;*/
    border: 1px solid rgba(0,0,0,0.66);
    padding-left: 5px;
    color: gray;
    font-size: 0.9em;
}

#full .subpage.classification.filtering li {
	display: none;
}

#full .subpage.classification.filtering .hit, #full .subpage.classification.filtering .hit > ul {	
    display: block !important;
}

#full .subpage.classification br {
	display: none;
}

#full .subpage.classification label .count {
	font-size: 0.9em;
	font-weight: normal;
	display:none;
}

.authorMode #full .subpage.classification label .count {
    display: inline;
}

#full .subpage.classification label .count:before {
	content: " (";
}

#full .subpage.classification label .count:after {
	content: ")";
}

#full .subpage.classification .search {
	display: none;
}

@keyframes fadeOutTeaser {
    from {background-color: color($pschy-color tint(85%));}
    to { background-color: transparent;}
}

#teasers {
    
    & .teaser.scrolled
    {
        animation-name: fadeOutTeaser;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
    }

    & .authorOnly {
        display: initial;
    }

    & h1 {
        margin-top: .67em;
        font-size: 1.4em;
		line-height: 1.2em;
    }

    & .links {
        margin: 1em 0 2em 0;
        color: $pschy-color;
        & a:before {          
            content: "> ";
        }
    }
}

#full .herold.subpage.classification a {
    color: $herold-color !important;
    border-bottom: 0 !important;
    margin-left: 19px;
}

#full .herold.subpage.classification h1 {
    color: $herold-color;
}

#cookie-richtlinien table td {
    border: 1px solid black; padding: 4px;
}