/* begin aside left */

/* begin tree */

 ul.fancytree-container {
     font-family: Verdana;
      overflow: auto;
   height: initial;
    overflow: visible;
    /*z-index: -2;*/
}

#tree {
  width: 240px;
  padding-left: 20px;
}


.ui-fancytree:focus {
    outline: none;
}

#tree .ui-fancytree>li>ul {
    padding: 0px;
}

ul.fancytree-container {
    border: 0;
}

span.fancytree-title {
    font-size: 12px;
}

.fancytree-node.custom-root .fancytree-title {
    font-size: 13px;
    margin: 5px;
    /*border-bottom: 1px dotted black;*/
    font-weight: bold;
    /*color: #696969 !important;*/
}

.fancytree-node.custom-root{
  background-color: rgba(154, 202, 64, 0.45);;
}

.fancytree-node.custom-root .fancytree-checkbox {
    display: none;
}

#tree > span
{
  position: relative;
  top: 25px;
  left: 190px;
  z-index: 10;
}

#tree ul.fancytree-container li {
    max-width: 99%;
	overflow: hidden;
}

#tree .toggle, #tree .clear {
    font-weight: bold;
}

#tree .toggle:hover, #tree .clear:hover, #tree .clear2:hover, #tree .toggle2:hover {
   cursor: pointer;
}

#tree .clear2, #tree .toggle2 {
   color: rgba(0, 0, 0, 0.6);
   font-weight: bold;
   font-size: 12px;
   margin-left: 10px;
}

#tree, ul.fancytree-container {
    /*background-color: #EFF5E4 !important;*/
    /*background-color: rgba(2, 49, 91, 0.015) !important;*/
    background-color: transparent;
    padding: 0;
}

#tree {
  overflow-x: hidden;
  overflow-y: auto;  
  list-style-type: none;
}

#tree .facetitem .facetitem {
  /*color: rgba(0,0,0,0.66);*/
  color: $pschy-color;
}

span.fancytree-title {
     font-family: Arial;
    padding-left: 0px;
      margin-left: 0px;
}
 span.fancytree-checkbox {
    margin-left: -3px;
}

.facettree {
    list-style-type: none;
    padding-left: 0;
    padding-right: 30px;
}

#tree label {
   font-size: 0.832em;
    margin: 0;
}

#tree .customroot {
    /*margin-left: -5px;*/
}

#tree .tree-clear {
    margin-top: -16px;
    left: -60px;
}

#tree .customroot > p {    
    padding: 5px 0;
    font-weight: bold;
    font-size: 0.882em;
    text-transform: uppercase;
}

#tree .facetitem {
    padding-left: 0;
    margin-top: 4px;
}

#tree .checkerbox {
   vertical-align: baseline;
}

#tree input[type = "checkbox"][class = expandbox]:not(:checked) ~ ul {
    display: none;
}

#tree input[type = "checkbox"][class = "expandbox"] {
    display: none;
    vertical-align: top;
}

/* collapsed expansion arrow */
#tree .expandlabel[for]::before {
    background-image: url('svg/arrow.svg');
    background-size: 10px 6px;
    display: inline-block;
    width: 10px;
    height: 6px;
    content: "";
    margin-left: 3px;  
    float: right;
    /*opacity: 0.5;*/
    
}

#tree label[for]:hover {
    cursor: pointer;
}


#tree .count {
  color: rgba(0,0,0,0.35);
}

/* expanded expansion arrow */
#tree input[type = "checkbox"][class = expandbox]:checked ~ .expandlabel[for]::before {
   transform: rotate(180deg);
}

#tree .expandlabel {
    vertical-align: top;
    margin-top: 3px;
}

/* end tree */

/* begin tagcloud */

#tagcloud h1 {
  font-family: Arial;
  font-size: 12px;
  margin-top: 10px;
  border-bottom: 1px dotted black;
  font-weight: bold;
  color: #696969 !important;
}

#tagcloud a {
  background: rgba(211, 211, 211, 0.29);
  border: 1px solid lightgray;
   border-radius: 5px;
  padding: 2px;
  margin:  5px 1px;
  white-space:pre-line;
  display: inline-block;
  font-size: 0.8em;
  font-family: Arial;

}

#tagcloud {
    /*background-color: #EFF5E4;*/
    padding-left: 3px;
}

/* end end */

/* begin actionArea */

#actionArea h2 {
    position: absolute;
    top: 20px;
    color: rgba(211, 211, 211, 0.4);
    transform: rotate(-30deg);
}

#actionArea {
  flex: 0 0 150px;
  padding: 5px;
  /*background-color: #EFF5E4;*/
  overflow: hidden;
}

#actionArea> img {
  /*  margin: 0;
  padding: 0;*/
   width:  auto;
  height: 150px;
   display: block;
    margin-left: auto;
    margin-right: auto;

}

#prefLink {
  /*background-color: #EFF5E4;*/
}

#prefLink img {
  width: 16px;
}

/* end action Area */

/* end aside left */

span.fancytree-node {
  padding-left: 7px;
}

.fancytree-node.custom-root {
  margin-top: 0px;
  margin-bottom: 5px;
}

.fancytree-lastsib.custom-root {
  margin-top: 20px;
}

span.fancytree-expander {
  margin-right: 3px;
}

#tree .tree-clear {
    font-size: 11px;
    color: gray; 
    position: absolute;
}

#tree .tree-clear:hover {
  cursor: pointer;
}

#tree .custom-root.fach .tree-clear
{
  right: -52px;  
}


#tree .custom-root.src .tree-clear
{
  right: -125px;  
}



.checkerbox {
  display: none;
    opacity: 0;
    filter: alpha(opacity=0);
}
/*
.checkerbox + label {
    position: relative;
}

.checkerbox + label:before {
    content: '';
    display: inline-block;
    visibility: visible;
    left: 0;
    width: 0.6em;
    height: 0.6em;
    margin: 0 0.3em 0 -1em;
    line-height: 0.6;
    font-size: 1.2em;
    text-align: center;
    
    border: 1px solid #999;
}

.checkerbox:checked + label:before {
    content: '✔';
}
*/
.checkerbox:checked + label {
    font-weight: bold;
}
