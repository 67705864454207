
#full .full_header {
    margin-top: -9px;
    font-size: 15px !important;
}

#full_view .buttons {
    position: absolute;
    right: 0px;
    top: 0px;
}

button.bookmark.active .glyphicon {
    color: orange;
}

#full button.bookmark {
    position: absolute;
    left: -24px;
}

#full textarea.form-control {
    border: 1px dotted black;
}

#full_empty {
    position: relative;
    top: 1em;
    left: .5em;
    font-size: 20em;     /*opacity: 0.1;*/
    color: rgba(239, 245, 228, 0.5);
}

#full_view .buttons,
#full_bread {
    display: none;
}

.layout_1 .articleTOC,
.layout_2 .articleTOC {
    display: none;
}

.layout_3 #full .lexinfo {
    clear: both;
}

.layout_3 #full .name_abbr {
    display: inline-block;
    width: 585px;
}

body.notAuthorized #toc {
    opacity: 0.25;
    /* pointer-events not supported by IE9 */
    pointer-events: none;
}

.toc-container {
    font-size: 0.941em;
    color: color(black tint(25%));
    margin-top: 28px;

	& .moreResults {
		margin-top: 28px;
	}
}

.toc-header {
    font-weight: bold;
    padding-bottom: 10px;
}

#toc {
    margin-top: -28px;
}

#toc ul {
    padding: 0;
    list-style-type: none;
    margin-top: 28px;

    & li {
        margin-left: -10px;
        padding: 1px;
        padding-left: 10px;

        cursor: pointer;
        margin-bottom: 9px;
    }
}

#toc ul:first-child li:hover:after {
    background-image: url('svg/arrow.svg');
    background-size: 12px 8px;
    display: inline-block;
    width: 12px;
    height: 8px;
    content: "";
    margin-left: 3px;    
    transform: rotate(180deg);
}

/*#toc ul:first-child li:first-child {
   color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
}*/

#toc ul:first-child li.current {
    font-weight: bold;
}

#toc ul:first-child li.current:hover:after {
    background: 0;
}

#toc ul:first-child li.current ~ li:hover:after {    
    transform: rotate(0deg);
}

#full .fbb.small {
    float: right;
    font-weight: normal;
    font-size:  0.5105em;
    padding: 0.5em;
    line-height: 1em; 
}

#full {
    & .fbb.large, & .printButton {
        float: right;
        height: 35px;
        padding: calc(35px - 2.1em);
        margin-left: 10px;
    }
}

#full .fbb.large {
    min-width: 165px;
}

#full .summary .fbb {
    margin-right: -15px;
    margin-top: -15px;
    font-size: 0.6em;
}

.naContent {
    background-image: url('ui/fakeart_all_blurry.jpg');
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 65vh;
}

.naButtons {
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 14px;

    & svg {
        width: 80px;
        height: 70px;
        fill: currentColor;
        margin: 15px;
    }
}

.naButton {
    flex-grow: 1;
    margin: 5px;
    min-width: 200px;
    max-width: 233px;
    height: 210px;
    color: white;
    background-color: $pschy-color;
    text-align: center;

    padding: 15px;

    &:hover {
        cursor: pointer;
        background-color: color($pschy-color tint(25%))
    }

    & .button-inner {
        @include vcenter();
    }
}

.naContent.herold .naButton {
    background-color: $herold-color;
    height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 20px;
    height: auto;

    & button {
        background-color: transparent;
        border: 1px solid white;
        font-weight: normal;
        padding: 5px 35px;
        margin-top: 15px;
    }
}

body:not(.notAuthorized) {
    & .not-authorized {
        display: none;
    }
}