#tree {
	height: 100%;
}
#tree .facetitem {
	position: relative;
	width: 100%;
}

#actions {
	min-height: 40px;
	height: auto;
}
#tree {
	width: auto;
}

.search_bar {
	min-width: auto;
}

#search {
	width: 100%;
}
.search_field {
	width: 100%;
}

.moreResults:hover {
	cursor: pointer;
}


.startView #logo-small {
	display: none !important;
}
.startView .search_bar > div {
	display: block;
}
.startView #search {
	padding-left: 0;
}
.logo-small {
	width: 38px;
	vertical-align: middle;
}

#actions li.logo {
	display: none;
	float: left;
	/*margin-left: -10px;*/
	& svg {
		fill: $pschy-color;
	}
}

.search_bar > div {
	display: block;
}
#logo-small {
	display: none;
}
.search_bar .moreResults {
	display: none;
}

body.startView .moreResults {
	display: none !important;
}

footer.footer-inner {
	display: none;
}

@media (max-height: 900px), (max-width: 870px) {
	/* Hide outer footer */
	footer.footer-outer {
		display: none;
	}
	footer.footer-inner {
		display: table;
	}

	#cbAlert {
		bottom: 0;
	}


	#main-area {
		height: 100%;
	}
	#container {
		margin-bottom: 0;
	}

	#cookie-banner {
		bottom: 0;
	}
}

/* One link per row in footer instead of word wrap */
@media (max-width: 870px) {
	footer ul {
		padding: 20px;
		text-align: center;
		& li, & li:last-child {
			display: block;
			float: none;
			padding: 5px;
			margin: 0;
		}
	}
}

/* Reduce header width */
@media (max-width: 1366px) {
	header, #articleContainer {
	  padding-right: 1.4em;
	}
	#actions li {
		margin-left: 10px;
	}
	.actions-container {
		margin-left: -10px;
	}
	#actions .dropdown>span {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media (max-width: 1024px) {
	#container {
		display: block;
	}

	#full .lexinfo, #full .legende, #full article .section.authorOnly {
		margin: 0;
		float: none;
		width: auto;
		max-width: 100%;
	}
	#full .lexinfo > ul, #full .legende > span, #full article .section.authorOnly>div {
		width: 100%;
		margin-left: 0;
	}
	#full .asset img {
		margin: 0 0;
	}
	#font-size-action span+span {
		margin-left: 15px;
	}
	.asset.table {		
	  -webkit-overflow-scrolling: touch;			
	   overflow: auto;			
	}
}

@media (max-width: 700px) {
	.search_bar > div+div {
		padding-left: 15px;
	}
	#actions li {
		margin-left: 5px;
	}
	.actions-container {
		margin-left: -5px;
	}
}

@media (max-width: 480px) {
	/* Padding between content and right screen edge */
	.articleView #articleContainer,
	header,
	.articleView header,
	body.startView header,
	#resultView
	{
		padding-right: 10px;
		padding-left: 10px;
	}

	body:not(.startView) {
		& .articleView #articleContainer {
			padding-right: 10px;
		}
		/* Move (P) to first row */
		& #actions .logo {
			display: block;
		}
		& #logo-small {
			display: none !important;
		}
		& #search {
			padding-left: 0;
		}
		& .search_bar .moreResults .btn {
			width: 6em;
			font-size: 10px;
		}
	}
}

/** Tables */

/* On wide screen: Make big tables span over three columns */
@media (min-width: 1440px) {
	#full .table.big {
		width: 150%;
	}
	#full .table.big .legende {
		margin: 0 0 1em 0;
		float: none;
		width: auto;
		max-width: 100%;
	}
	#full .table.big .legende > span {
		width: 100%;
		margin-left: 0;
	}
}


/* Small screens & landscape => fit header into one single row */
@media(max-height: 860px) and (min-width: 600px) and (orientation: landscape) {
	body:not(.startView) {
		& .actions-container {
			float: right;
			/*max-width: 100%;*/
			width: auto;
		}
		& .search_bar {
			padding-top: 10px;
		}
	}
	body:not(.startView) {
		& #container {
			height: calc(100% - 55px);
		}
		& header {
			height: 55px;
		}
	}
	
	#right-area {
		/*padding-top: 55px;*/
	}

}
@media(max-height: 860px) and (min-width: 600px) and (orientation: landscape) and (max-width: 800px) {
	body:not(.startView) .search_bar > div+div {
		padding-left: 1.4em;
	}
	#actions > li {
		margin-left: 5px;
	}
	.actions-container {
		margin-left: -5px;
	}
	
	#actions > li.logo {
		padding-left: 10px;
	}
}

@media(max-width: 800px) {
	body.startView #logo-stoerer {
		
        & > svg {
			height: 100px;
			width: 100px;
        }
    }
}

/*@media(max-width: 800px) {
	body.startView #meduplus-stoerer {
		display: none !important;
    }
}*/


@media(max-width: 700px) {
	body.startView #logo-stoerer {
		
        & > svg {
			height: 80px;
			width: 80px;
        }
    }
}

@media(max-width: 600px) {
	body.startView #logo-stoerer {
		
        & > svg {
			height: 60px;
			width: 60px;
        }
    }
}

@media(max-width: 300px) {
	body.startView #logo-stoerer {
		
        & > svg {
			height: 40px;
			width: 40px;
        }
    }
}

@media(min-width: 1000px) {
	body.startView #logo-stoerer {
		right: 23px;
    }
}